import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import useWindowDimensions from "../utils/windowSize";
import { Link } from "gatsby";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ACTIVE_PAGE } from "../components/nav/utils";
import styled from "@emotion/styled";
import { HeaderTextContainer } from "../components/styled-components/Globals/GlobalStyledComponents";

export default function PointCloudPage() {
  const { width } = useWindowDimensions();
  const desktopPointCloud = () => (
    <iframe
      src="https://www.youtube.com/embed/Byq7eWVOW0M?autoplay=1&mute=1&controls=0&showinfo=0&loop=1&playlist=Byq7eWVOW0M"
      className="deliverable__iframe"
      scrolling="no"
      frameBorder="0"
      allowFullScreen
      title="point-cloud"
      allow="autoplay"
    />
  );
  const mobilePointCloud = () => (
    <img
      className="point-cloud-video"
      src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fpoint-cloud%2Fnew-septa-gif.gif?alt=media&token=763f4c9b-069e-4f88-8ec3-e0efd6ce99e9&_gl=1*trihs*_ga*MjM4OTYzNDY0LjE2OTQxMDMwOTE.*_ga_CW55HF8NVT*MTY5NzQ3NTUyNS44NC4xLjE2OTc0Nzc0NTYuMjQuMC4w"
      alt=""
    />
  );

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.POINTCLOUD}>
      <Seo
        title="Point Cloud Services | Robotic Imaging"
        description="Sample Point Cloud services provided by Robotic Imaging."
      />
      <header className="site__header"></header>

      <main className="content">
        <HeaderTextContainer>
          <strong>
            Capture Every Detail with Our 3D Laser Scanning Solution
          </strong>
          <p>
            <span>
              Unlock new levels of precision and efficiency in your
              architectural projects with our 3D laser scanning service.
              Architects can now quickly and accurately capture detailed
              as-built conditions, complex geometries, and site measurements
              with unparalleled accuracy.
            </span>
            <span>
              Whether you're working on historical preservation, renovation, or
              new construction, our 3D laser scanning technology ensures every
              dimension and element is meticulously recorded, reducing manual
              effort and eliminating guesswork.
            </span>
            <span>
              From initial surveys to final design, this cutting-edge solution
              enhances your workflow, allowing for seamless integration into CAD
              and BIM software. Elevate your architecture with precise, reliable
              data you can trust - explore the future of design with 3D laser
              scanning.
            </span>
          </p>
        </HeaderTextContainer>
        {width
          ? width >= 650
            ? desktopPointCloud()
            : mobilePointCloud()
          : null}
        <HeaderTextContainer>
          <p>
            <span>
              Point Clouds are plotted data points representing space, with each
              point in an X, Y, Z coordinate to represent a surface. When you
              break down a point cloud's file structure, it is a bunch of
              arbitrary or located XYZ values and RGB values (if it is
              colorized).
            </span>
            <span>
              The general definition is a set of data points making up a 3D
              representation of the world with an XYZ coordinate for every pixel
              or laser.
            </span>
            <span>
              Robotic Imaging generates point clouds with laser scanners and
              drones helping the industry with a few of the major problems with
              point clouds:
            </span>
          </p>
        </HeaderTextContainer>

        <RowContainer>
          <ol>
            <li>File Sizes and Computing</li>
            <li>Accuracy of Registered Point Cloud</li>
            <li>Accuracy of Completed CAD / Revit Model to Point Cloud</li>
            <li>Software Compatibility</li>
            <li>Mult-Site Point Data Cloud Collection</li>
          </ol>

          <video
            className="video"
            src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2F2D%2F182Flatbush%20Ave_B.mp4?alt=media&token=a6120132-0e11-48e3-b4e8-f7cc5e34f9a0"
            autoPlay
            loop
            muted
            playsInline
            style={{ maxHeight: "150px" }}
          />
        </RowContainer>

        <br />

        <video
          className="point-cloud-gif"
          src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2FPointCloud%2Fnew%20Point%20Cloud%20Animation_5MB.mp4?alt=media&token=3871d8ba-62b7-4f41-b46e-84234373cf31&_gl=1*11v5fsf*_ga*NTg2OTE3MTgyLjE2OTkzODUxMjI.*_ga_CW55HF8NVT*MTY5OTM4NTEyMS4xLjEuMTY5OTM4NTMwOS43LjAuMA.."
          autoPlay
          loop
          muted
          playsInline
        />

        <img
          className="img"
          src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fpoint-cloud%2FScan%20the%20City%20point%20cloud.png?alt=media&token=84b8adb9-ac31-4ba5-9dbf-6427bf248b08"
          alt=""
          style={{ maxWidth: "1050px" }}
        />

        <div className="page-navigation">
          <Link to="/vr" className="page-navigation-prev" title="Previous page">
            <div>
              <ChevronLeftIcon />
              <span>Previous Page</span>
            </div>
            {width && width >= 650 ? <span>Visual</span> : null}
          </Link>
          <Link
            to="/aerial-drone"
            className="page-navigation-next"
            title="Next page"
          >
            <div>
              <span>Next Page</span>
              <ChevronRightIcon />
            </div>
            {width && width >= 650 ? <span>Drone</span> : null}
          </Link>
        </div>
      </main>
    </SiteWrapper>
  );
}

const RowContainer = styled.div`
  display: flex;
  gap: 10px;

  > ol {
    margin: 0px;
    font-size: 18px;
  }

  > video {
    margin-right: 10%;
    margin-left: auto;

    @media (max-width: 914px) {
      margin-right: 0px;
    }
    @media (max-width: 568px) {
      display: none;
    }
  }
`;
